import React from 'react'

class Layout extends React.Component {
  componentDidMount() {
    const body = document.getElementsByTagName('body')[0];
    const script = document.createElement('script');
    script.src = '/js/nav.min.js';
    body.appendChild(script);
    const scriptApp = document.createElement('script');
    scriptApp.src = '/js/app.js';
    body.appendChild(scriptApp);
  }

  render() {
      return <></>;
  }
}

export default Layout
