'use strict'

import React from 'react'

class Header extends React.Component {
  render() {
    return <header className="m-header">
      <div className="m-header-title">
        <a href="/" target="_self">
          <img className="logo" width="36" src="/img/1x.png" srcSet="/img/2x.png 2x" />
          <h6 className="name">dwb - blog</h6></a>
          </div>
          <nav className="m-header-nav js-nav ">
            <ul className="m-header-items">
              <li className="item">
                <a className="href" href="/">博 客</a>
              </li>
              <li className="item">
                <a className="href" href="/design">设 计</a>
              </li>
              <li className="item">
                <a className="href" href="/hobby">爱 好</a>
              </li>
              <li className="item">
                <a className="href" href="/reading">阅 读</a>
              </li>
              <li className="item">
                <a className="href" href="/about">关 于</a>
              </li>
            </ul>
          </nav>
          <div id="js-nav-btn" className="m-header-btn ui-font-ydoc">&#xf020;</div>
      </header>;
  }
}
export default Header;
  